import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MensajesEncuestasAreas } from 'src/environments/environment';

@Component({
  selector: 'app-encuestas',
  templateUrl: './encuestas.component.html',
  styleUrls: ['./encuestas.component.scss']
})
export class EncuestasComponent implements OnInit {

  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabGroup', { static: false }) tabGroup: any;

  curPregunta = 1;
  init = false;
  preguntas = [];
  loading = false;
  isBancoSangre = false;
  mensajeEncuesta;
  mensajeEncuestaPorServicio;
  constructor(
    @Inject(MAT_DIALOG_DATA) public inp: { encuesta: any, isBancoSangre:boolean, areaId: any, servicioId: any },
    public dialogRef: MatDialogRef<EncuestasComponent>,
    public agendaService: AgendaAmbulatoriaService,
    public utils: UtilsService
  ) {
    this.setMensajeEncuesta();
    this.preguntas = this.inp.encuesta;
    this.isBancoSangre = this.inp.isBancoSangre ;
  }

  ngOnInit() {
    setTimeout(() => {
      this.init = true;
    }, 1500)
  }

  setMensajeEncuesta(){

    try{
      const areaId = String(this.inp.areaId);
      const servicioId = String(this.inp.servicioId);
      
      if(MensajesEncuestasAreas[areaId]){
        if(MensajesEncuestasAreas[areaId].mensajeActivo){
          this.mensajeEncuesta = MensajesEncuestasAreas[areaId];
        }
  
        if( MensajesEncuestasAreas[areaId].mensajesPorServicio[servicioId].mensajeActivo){
          this.mensajeEncuestaPorServicio = MensajesEncuestasAreas[areaId].mensajesPorServicio[servicioId];
        }
      }
    }catch(err){

    }
  }
  validar(){
    const preguntasFaltantes = [];
    this.preguntas.forEach((val, key) => {
      if (val.tipo === 'seleccion_unitaria' && !val.seleccion) {
        preguntasFaltantes.push(key + 1)
      }
    });
    return preguntasFaltantes;
  }

  async confirmar(action) {
    
    const respuestas = []

    if (action) {
 
      const preguntasFaltantes = this.validar();
      
      if(preguntasFaltantes.length > 0){
        this.utils.mDialog("Estimado Paciente", "Aún falta por contestar las preguntas: " + preguntasFaltantes.join(','), "message", false);
        return false;
      }

      this.preguntas.forEach((val, key) => {

        if (val.tipo === 'seleccion_unitaria') {
          respuestas.push({ idPregunta: String(val.idPregunta), respuestasOpcion: [val.seleccion.idOpcion] })
        }

        if (val.tipo === 'seleccion_multiple') {
          const opciones = [];
          val.opciones.forEach((vop, vopk) => {
            if (vop.enable) {
              opciones.push(vop.idOpcion)
            }
          });

          respuestas.push({ idPregunta: String(val.idPregunta), respuestasOpcion: opciones })
        }

      });

    }

    this.dialogEvent.emit({ action, respuestas  });
    this.dialogRef.close();
  }
}
